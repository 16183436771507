import { render, staticRenderFns } from "./moreSaleData.vue?vue&type=template&id=1600f36e&scoped=true&"
import script from "./moreSaleData.vue?vue&type=script&lang=js&"
export * from "./moreSaleData.vue?vue&type=script&lang=js&"
import style0 from "./moreSaleData.vue?vue&type=style&index=0&id=1600f36e&scoped=true&lang=css&"
import style1 from "./moreSaleData.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1600f36e",
  null
  
)

export default component.exports
import {QTable,QBtnToggle} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QTable,QBtnToggle})
