//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { LocalStorage } from "quasar";
export default {
  name: "moreSaleData",
  data() {
    return {
      categoryData: [],

      thisNumSort: [],

      thisMoneySort: [],

      allNumSort: [],

      allMoneySort: [],

      allSortBy: "allNum",
      thisSortBy: "thisNum",
    };
  },
  created() {
    this.seg = this.$route.query.seg;
    this.categoryData = JSON.parse(LocalStorage.getItem("categoryCata"));
    this.thisNumSort = JSON.parse(LocalStorage.getItem("thisNumSort"));
    this.thisMoneySort = JSON.parse(LocalStorage.getItem("thisMoneySort"));
    this.allNumSort = JSON.parse(LocalStorage.getItem("allNumSort"));
    this.allMoneySort = JSON.parse(LocalStorage.getItem("allMoneySort"));
  },
  activated() {
    window.document.title = "门店业绩";
  },
};
